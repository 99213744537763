import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";
const API = apiPath.contractor;
const dictionary = apiPath.dictionary;
/**
 * 获取承包商人员信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoContractorPersonById(id) {
  return axios.get(API + "/info/contractor_persons/" + id);
}

/**
 * 分页查询入场时间记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageEntryTime(params) {
  return axios.get(API + "/page/contractor-person-enter-records", { params });
}

/**
 * 分页查询人员培训
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPagePersonTraining(params) {
  return axios.get(API + "/page/contractor-person-training-records", {
    params
  });
}

/**
 * 查询人员项目
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPagePersonProject(params) {
  return axios.get(`${API}/page/contractor/person/projects`, { params });
}

/**
 * 获取承包商人员资质
 * @param {*} params
 */
export function getListContractorsPersonLicense(params) {
  return axios.get(API + "/list/contractors/person/license", { params });
}

/**
 * 请求人员专业资质
 * @param {*} params
 */
export const getPersonTypes = params =>
  axios.get(dictionary + "/person/qualification/types", { params });

/**
 * 分页查询app端承包商人员信息
 * @param {*} params
 */
export const getPersonList = params =>
  axios.get(API + "/page/app/contractor_persons", { params });
/**
 * 查询承包商人员信息（不分页）
 * @param {*} params
 */
export const getAllPersonList = params =>
  axios.get(API + "/list/contractor_persons", { params });
/**
 *获取培训类别
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectTrainType() {
  return axios.get(API + `/select/train/type`);
}
/**
 *获取培训级别
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectTrainLevel() {
  return axios.get(API + `/select/train/level`);
}
/**
 * 新增人员培训
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createPersonTraining(data) {
  return axios.post(API + `/add/contractor-person-training-records`, data);
}
