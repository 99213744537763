<template>
  <div>
    <van-nav-bar
      title="承包商人员"
      left-arrow
      :border="false"
      fixed
      @click-left="$router.go(-1)"
    >
    </van-nav-bar>
    <div class="header">
      <div class="filter-box-wrapper">
        <van-search
          v-model="query.fuzzyName"
          placeholder="请输入姓名"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.popupKey)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}个结果</div>
        </div>
      </div>
    </div>
    <div class="content">
      <van-pull-refresh
        ref="vanList"
        v-model="refreshing"
        class="alarm-list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="loadError"
          error-text="加载失败，请稍后再试！"
          @load="onLoad"
        >
          <div
            v-for="item in personList"
            :key="item.id"
            class="alarm-item"
            @click="gotoDetail(item.id)"
          >
            <div class="content">
              <div class="left">
                <span>{{ item.contractorPersonName }}</span>
                <span v-if="item.blackListStatus === 1" class="blackFlag"
                  >黑</span
                >
              </div>

              <div class="right">
                <div class="constractor">
                  {{ item.contractorName }}
                </div>
                <div class="statusBox">
                  <div
                    :class="[
                      'status',
                      item.entranceStatus == 0 ? 'red' : '',
                      item.entranceStatus == 1 ? 'green' : '',
                      item.entranceStatus == 2 ? 'blue' : '',
                      item.entranceStatus == 3 ? 'gary' : ''
                    ]"
                  >
                    {{ item.entranceStatusName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      include-children
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[2].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[2].title"
      :list.sync="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visiable"
      @change="v => popupGradeChange(v, 1, 2)"
      @close="popupVisibleObj[2].visiable = false"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[3].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[3].title"
      :list.sync="popupVisibleObj[3].list"
      :visible="popupVisibleObj[3].visiable"
      @change="v => popupGradeChange(v, 2, 3)"
      @close="popupVisibleObj[3].visiable = false"
    />
  </div>
</template>

<script>
import { getPersonList } from "@/api/psm/contractor";
import { getSelectContractorsNames } from "@/api/base.js";
// NOTE: filterList和popupVisibleObj参考alarm内使用

export default {
  name: "ContractorPersonList",
  components: {},
  data() {
    return {
      department: "",
      departmentVisible: false,
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      query: {
        fuzzyName: "",
        department: "",
        subDepartment: true,
        contractorIds: "",
        page: 1,
        size: 10
      },
      filterList: [
        {
          name: "所属部门",
          type: "select",
          text: "",
          popupKey: 1,
          isActive: false
        },
        {
          name: "所属承包商",
          type: "select",
          popupKey: 2,
          text: "",
          isActive: false
        },
        {
          name: "状态",
          type: "select",
          popupKey: 3,
          text: "",
          isActive: false
        }
      ],
      popupVisibleObj: {
        2: {
          visiable: false,
          title: "所属承包商",
          value: "",
          list: []
        },
        3: {
          visiable: false,
          title: "状态",
          value: "",
          list: []
        }
      },
      total: 0,
      personList: []
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.onLoad();
    // window.sessionStorage.removeItem("redirectURL");
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    refreshQuery(val, includeInfo) {
      this.filterList[0].text = val.label;
      this.query.department = this.department;
      this.query.subDepartment = includeInfo?.include;
      this.onSearch();
    },
    onSearch(val) {
      this.query.page = 1;
      this.personList = [];
      this.total = 0;
      this.getList();
    },
    onCancel() {
      this.onSearch();
    },
    async getList() {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      try {
        const { list, total } = await getPersonList(this.query);
        this.total = total;
        this.loadError = false;
        this.loading = false;
        if (list && list.length) {
          this.personList = this.personList.concat(list);
          this.query.page++;
        } else {
          this.finished = true;
        }
      } catch (err) {
        this.loading = false;
        this.finished = true;
        this.loadError = true;
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.personList = [];
        this.query.page = 1;
        this.total = 0;
        this.refreshing = false;
      }
      this.getList();
    },
    onRefresh() {
      this.query.page = 1;
      this.personList = [];
      this.total = 0;
      this.getList();
    },
    async selectCon(popupKey) {
      let data = [];
      if (popupKey === 1) {
        this.departmentVisible = true;
      } else if (popupKey === 2) {
        data = await getSelectContractorsNames({
          orgCodeInList: this.userInfo.orgCode
        });
      } else if (popupKey === 3) {
        data = [
          { label: "启用", value: "0" },
          { label: "禁用", value: "1" },
          { label: "黑名单", value: "2" }
        ];
      }
      this.popupVisibleObj[popupKey].visiable = true;
      this.popupVisibleObj[popupKey].list = data;
    },
    popupGradeChange(v, filterListIdx, popupKey) {
      const vObj = v.length > 0 ? v[0] : {};
      this.popupVisibleObj[popupKey].value = vObj.value || "";
      this.filterList[filterListIdx].text = vObj.label || "";
      if (popupKey === 1) {
      } else if (popupKey === 2) {
        this.query.contractorIds = vObj.value || "";
      } else if (popupKey === 3) {
        this.query.state = vObj.value || "";
      }
      this.onSearch();
    },
    gotoDetail(id) {
      this.$router.push({
        name: "ContractorPerson",
        params: { id, type: "list" }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .select-popup-grade .popup-list {
  overflow: auto;
}
.header {
  padding: 58px 0 0;
  .search-bar {
    padding-bottom: 0;
  }
  .alarm-search {
    margin: 0;
  }
  .filter-box {
    padding: 10px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;
    &::-webkit-scrollbar {
      display: none;
    }
    .filter-item {
      display: inline-block;
      border-radius: 10px;
      background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;
      &.select {
        position: relative;
        padding-right: 18px;
        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }
      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .search-condition {
    display: flex;
    justify-content: center;
    .loading {
      display: inline-block;
    }
    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }
    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      padding: 12px 0 0;
    }
    // .condition-switch {
    //   color: $--color-icon;
    //   i {
    //     font-size: 5vw;
    //   }
    // }
  }
}
.content {
  .van-list {
    height: calc(100vh - 229px);
  }
  .alarm-list {
    flex: 1;
    overflow-y: auto;
    z-index: 9;
    .alarm-item {
      padding: 14px 16px 0px 16px;
      border-bottom: 8px solid #eceef2;
      font-size: 4vw;
      min-height: 34px;
      .content {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #3b4664;
        line-height: 20px;
        .left {
          width: 90px;
          .blackFlag {
            display: inline-block;
            margin-left: 4px;
            background: #636971;
            border-radius: 2px;
            color: #fff;
            font-size: 11px;
            line-height: 12px;
            padding: 1px;
            text-align: center;
            transform: scale(0.9, 0.9);
          }
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .statusBox {
            min-width: 52px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
          }
          .status {
            border-radius: 2px 2px 2px 2px;
            font-size: 11px;
            padding: 2px;
            text-align: center;
            line-height: 11px;
          }
          .blue {
            background: rgba(22, 118, 255, 0.15);
            border: 1px solid #1676ff;
            color: #1676ff;
          }
          .red {
            background: rgba(251, 113, 113, 0.15);
            border: 1px solid #fb7171;
            color: #fb7171;
          }
          .green {
            background: rgba(100, 174, 8, 0.15);
            border: 1px solid #64ae08;
            color: #64ae08;
          }
          .gary {
            background: rgba(99, 105, 113, 0.15);
            border: 1px solid #636971;
            color: #636971;
          }
        }
      }
    }
  }
}
</style>
